import React from "react";
import logo from "../images/logo.svg"
import maintenance from "../images/mugmechanic.svg";


const IndexPage = () => {
  return (
    <main className=" bg-gradient-to-br from-posboss-gradient-primary to-posboss-gradient-secondary min-h-screen p-8 flex items-center justify-center ">
      <div className="text-center px-4 space-y-2">
        <div className="p-8 rounded shadow-md bg-white space-y-8 max-w-screen-md">
          <img src={logo} className="w-1/4 mx-auto pb-12" />
          <img src={maintenance} className="w-1/2 mx-auto" />
          <h1 className="text-2xl">
            posBoss is currently down for scheduled maintenance
          </h1>
          <p>
            Our maintenance window is between 11:00pm and 11.59pm NZDT / 9:00pm and  9.59pm
            AEDT
          </p>
          <p>
            Should the site be unavailable after the times above, or if you are
            having any ongoing issues, please contact us through the support
            messenger or email{" "}
            <a
              className="text-blue-600 hover:underline cursor-pointer"
              href="mailto:hello@posbosshq.com"
            >
              hello@posbosshq.com{" "}
            </a>{" "}
          </p>
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
